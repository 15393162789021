import React from "react"
import { TopHeaderMenu } from "../components/TopHeaderMenu"
import { Outlet } from "react-router-dom"

export default function Layout() {
    return (
        <div className="front">
            <TopHeaderMenu />
            <Outlet />
        </div>
    )
}
