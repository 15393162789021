import React from "react"

export default function Hjem() {
    return (
        <div>
            <header className="frontResidencen">
                <h1 className="headerTitle">RESIDENCEN</h1>
                <p className="headerParagraph">Nerja, España</p>
            </header>
            <div className="frontLight">
                <a href="informasjon">
                    <h1>Informasjon</h1>
                </a>
                <p>Leie, kontaktinformasjon, med mer</p>
            </div>
            <div className="frontDark">
                <a href="huskeliste">
                    <h1>Huskeliste</h1>
                </a>
                <p>Ved ankomst og avreise</p>
            </div>
            <div className="frontLight">
                <a href="anbefalinger">
                    <h1>Anbefalinger</h1>
                </a>
                <p>Restauranter, aktiviteter, og mer</p>
            </div>
        </div>
    )
}
