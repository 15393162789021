import "./App.css"
import React from "react"
import { BrukerProvider } from "./BrukerContext"
import { Innhold } from "./Innhold"

function App() {
    return (
        <BrukerProvider>
            <Innhold />
        </BrukerProvider>
    )
}

export default App
