import React, { createContext, useContext, useState } from "react"

export const BrukerContext = createContext(null)

export const BrukerProvider: React.FC = ({ children }) => {
    // State to hold the username
    const [bruker, setBruker] = useState("")

    // Function to update the username
    const oppdaterBruker = (newUsername) => {
        setBruker(newUsername)
    }

    return (
        <BrukerContext.Provider value={{ bruker, oppdaterBruker }}>
            {children}
        </BrukerContext.Provider>
    )
}

export const useBrukerContext = () => useContext(BrukerContext)
