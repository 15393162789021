import React from 'react';
import { badListe, bilListe, genereltListe, kjokkenListe } from "./huskelistepunkter";

const Huskeliste = () => {
   
    function fjernMarkeringer() {
        const checkboxes = document.getElementsByName('huskelistepunkt') as NodeListOf<HTMLInputElement>;
        checkboxes.forEach(checkbox => checkbox.checked = false);
    }

    return (
        <div>
            <div className="page">
                <h1 className="topHeader">HUSKELISTE</h1>
                <h2>👇</h2>
            </div>
            <div className="underPage">
                <div>
                    <div style={{ marginBottom: 100 }}>
                        <h2>Ved ankomst</h2>
                        <p>
                            Vannlåsene tørker ut, fyll vann i slukene på badene og på takterrassen. Gjør det igjen
                            dersom lukt.
                        </p>
                    </div>
                    <div>
                        <h2>Ved avreise</h2>
                        <button type="button" id="unselect-all" onClick={fjernMarkeringer}>
                            Nullstill markeringer
                        </button>

                        <h3 style={{ marginTop: 80 }}>🧑‍🍳 Kjøkken</h3>
                        {kjokkenListe.map((item, index) => (
                            <label key={index} className="checkmarkLabel">
                                <input type="checkbox" name="huskelistepunkt" className="checkbox" />
                                {item}
                            </label>
                        ))}

                        <h3 style={{ marginTop: 80 }}>🛀 Bad</h3>
                        {badListe.map((item, index) => (
                            <label key={index} className="checkmarkLabel">
                                <input type="checkbox" name="huskelistepunkt" className="checkbox" />
                                {item}
                            </label>
                        ))}

                        <h3 style={{ marginTop: 80 }}>🚗 Bil</h3>
                        {bilListe.map((item, index) => (
                            <label key={index} className="checkmarkLabel">
                                <input type="checkbox" name="huskelistepunkt" className="checkbox" />
                                {item}
                            </label>
                        ))}

                        <h3 style={{ marginTop: 80 }}>📝 Annet</h3>
                        {genereltListe.map((item, index) => (
                            <label key={index} className="checkmarkLabel">
                                <input type="checkbox" name="huskelistepunkt" className="checkbox" />
                                {item}
                            </label>
                        ))}
                    </div>
                </div>
                <div className="hyttebokBox">
                    <h2>Husk å skrive i hytteboken!</h2>
                    <p>Derunder reparasjoner, innkjøp eller annet.</p>
                </div>
            </div>
        </div>
    );
}

export default Huskeliste;
