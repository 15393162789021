import React, { useEffect, useState } from "react"
import { Recommendation } from "../types/supabase"
import supabase from "../supabaseconfig"
import style from "./anbefalinger.module.css"
import { LeggTilAnbefalingModal } from "./LeggTilModal"

export default function Anbefalinger() {
    const [visModal, setVisModal] = useState(false)
    const [recommendations, setRecommendations] = useState<Recommendation[]>([])

    useEffect(() => {
        getRecommendations()
    }, [])

    async function getRecommendations(): Promise<void> {
        let { data, error } = await supabase()
            .from("recommendations")
            .select("*")

        if (error) {
            console.error("Error fetching recommendations:", error)
            return
        }

        if (data) {
            setRecommendations(data)
        }
    }

    return (
        <div>
            <div className="page">
                <h1 className="topHeader">ANBEFALINGER</h1>
            </div>
            <div className="underPage">
                <div className={style.layout}>
                    <button
                        style={{ alignSelf: "end" }}
                        type="button"
                        onClick={() => {
                            setVisModal(true)
                        }}
                    >
                        + Legg til ny anbefaling
                    </button>
                    {recommendations.map((it: Recommendation) => (
                        <div key={it.id} className={style.anbefaling}>
                            <p>
                                <strong>Navn</strong>
                            </p>
                            <p>{it.name}</p>
                            <p>
                                <strong>Anbefalt av</strong>
                            </p>
                            <p>{it.user}</p>
                            <p>
                                <strong>Rating</strong>
                            </p>
                            <p>{it.rating}</p>
                            <p>
                                <strong>Beskrivelse</strong>
                            </p>
                            <p>{it.description}</p>
                            <p>
                                <strong>Adresse</strong>
                            </p>
                            <p>{it.address}</p>
                            {it.url && (
                                <a className={"url"} href={it.url}>
                                    Nettside
                                </a>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <LeggTilAnbefalingModal
                vis={visModal}
                lukk={() => setVisModal(false)}
            />
        </div>
    )
}
